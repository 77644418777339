/**
 * @typedef {{
 *  price: number;
 *  priceDifference: number;
 *  hasStock: boolean;
 *  alternativeHasStock: boolean;
 *  priceFormatted: string;
 *  priceDifferenceFormatted: string;
 *  outOfStockFormatted: string;
 *  alternativeFormatted: string;
 * }} OptionStats
 */
/**
 * @typedef {{
 *   label: string;
 *   selected?: boolean;
 *   skeleton?: boolean;
 *   disabled?: boolean;
 *   variationId?: number;
 *   alternativeVariationId?: number;
 *   stats?: OptionStats;
 * }} Option
 */
/**
 * @typedef {{
 *   type: number;
 *   name: string;
 *   priority?: number;
 *   plural: string;
 *   highlight?: boolean;
 *   onlyShowForItemType?: ('Andere' | 'Spiel' | 'Konsole' | 'Zubehör')[];
 *   hideWhenLessThan?: number;
 *   hideWhenMoreThan?: number;
 *   hideDisabledTags?: boolean;
 *   hideOutOfStockTags?: boolean;
 *   hideAllOutOfStockTags?: boolean;
 *   hideAlternativeOnlyTags?: boolean;
 *   keepOutOfStockTags?: string[];
 *   keepAlternativeOnlyTags?: string[];
 *   alwaysShowTags?: string[];
 *   fillMissingTagsCount?: number;
 *   collapseAfterCount?: number;
 *   displayStats?: (keyof OptionStats)[];
 *   selectedDisplayStats?: (keyof OptionStats)[];
 *   displayStatsMaxCount?: number;
 *   displayAlternative?: boolean;
 *   displayStockIndicator?: boolean;
 *   displayLanguageFlag?: boolean;
 *   displayColorIndicator?: boolean;
 *   displayThumbnail?: boolean;
 *   sortByHasStock?: boolean;
 *   sortSelectedFirst?: boolean;
 *   ignoreTags?: number[];
 *   ignoredTagsSortOrder?: 'price' | 'salesrank';
 *   singleLine?: boolean;
 *   thumbnailOnly?: boolean;
 *   gridWidth?: number;
 *   skeleton?: number | string[];
 *   onlyLoadMatchingVariations?: boolean;
 * }} VariationGroup
 */
/**
 * @typedef {VariationGroup & {
 *   options: Option[];
 *   enabledOptions: Option[];
 *   hide?: boolean
 * }} DisplayGroup
 */

Vue.component("item-variation-select", {
  template: "#vue-item-variation-select",
  props: {
    itemId: [Number, String],
    initialItem: Object,
    /** @type {'Andere' | 'Spiel' | 'Konsole' | 'Zubehör'} */
    itemType: String,
    /** @type {VariationGroup[]} */
    variationGroups: Array,
    collapseAfterCount: {
      type: Number,
      default: 8,
    },
    soldOutText: {
      type: String,
      default: "Bald wieder da",
    },
    soldOutTooltip: {
      type: String,
      default: "Bald wieder da - Jetzt Kaufalarm aktivieren!",
    },
    alternativeAvailableText: {
      type: String,
      default: "Alternative verfügbar",
    },
    alternativeAvailableTooltip: {
      type: String,
      default: "Nur in Kombination mit anderen Merkmalen verfügbar.",
    },
    alternativeSoldOutText: {
      type: String,
      default: "Alternative ausverkauft",
    },
    alternativeSoldOutTooltip: {
      type: String,
      default: "Ausverkauft - Nur in Kombination mit anderen Merkmalen vorhanden.",
    },
    missingItemText: {
      type: String,
      default: "Nicht vorhanden",
    },
    missingItemTooltip: {
      type: String,
      default: "Artikelvariante leider nicht vorhanden",
    },
  },
  data: () => ({
    items: [],
    variationsData: {},
    freeTextData: {},
    currentConditionDescription: "",
    currentItem: undefined,
    currentVariationData: undefined,
    currentTags: {},
    /** @type {DisplayGroup[]} */
    displayGroups: [],
    loading: false,
  }),
  computed: {
    shownDisplayGroups () {
      return this.displayGroups.filter(group => !group.hide);
    },
    displayGroupTypes () {
      return new Set(this.shownDisplayGroups.map(group => group.type));
    },
  },
  methods: {
    index (retryIn = 1000) {
      this.loading = true;
      this.items = [];
      this.currentVariationData = {
        id: this.initialItem.variation.id,
        data: {
          ...this.initialItem.variation,
          ...this.initialItem,
        }
      };
      this.freeTextData = {
        ...this.freeTextData,
        [this.itemId]: this.initialItem.item,
      };
      this.displayGroups = this.variationGroups
        .filter(group => group.skeleton && (!group.onlyShowForItemTypes || group.onlyShowForItemTypes.includes(this.itemType)))
        .map(group => {
          const enabledOptions = (Array.isArray(group.skeleton) ? group.skeleton : Array.from({ length: group.skeleton }))
            .map((entry) => ({
              label: entry ?? 'Wird geladen...',
              selected: false,
              disabled: true,
              skeleton: true,
              stats: {
                priceDifference: 0,
                hasStock: false,
                alternativeHasStock: false,
                priceFormatted: "",
                priceDifferenceFormatted: "",
                outOfStockFormatted: "...",
                alternativeFormatted: "",
              },
            })
            );
          return {
            ...group,
            options: [],
            enabledOptions,
            hide: enabledOptions.length < (group.hideWhenLessThan || 0)
              || enabledOptions.length > (group.hideWhenMoreThan || Infinity),
          };
        });

      this.updateFreeText();

      Promise.all([
        this.getVariationData(this.currentVariationData.id).then((data) => this.currentVariationData = data),
        new Promise(
          (resolve) => $.get({
            url: "https://ankauf.konsolenkost.de/articleinfo/info_variations.php",
            data: { article_id: this.itemId },
          }).done(((response) => {
            if (response !== "undefined") {
              this.items.push(...response.articles);
              this.currentItem = response.articles.find((item) => item.article_id === this.itemId);
              this.displayGroups = []

              for (let group of this.variationGroups) {
                const tags = response.tags[group.type];
                const matchesItemType = !group.onlyShowForItemTypes || group.onlyShowForItemTypes.includes(this.itemType);
                if (tags && tags.length && matchesItemType) {
                  this.displayGroups.push({
                    ...group,
                    options: tags.map((label) => ({ label })),
                    enabledOptions: [],
                  });
                }
              }

              if (this.currentItem) {
                this.$nextTick(() => {
                  // Must be calculated after computed properties are re-evaluated
                  this.updateSelectedTags();
                  this.updateEnabledOptions();
                });
              }
            }

            this.loadTagVariations(this.displayGroups.reduce((acc, group) => {
              if (group.onlyLoadMatchingVariations) acc[group.type] = this.currentTags[group.type];
              return acc;
            }, {}));
            resolve();
          }).bind(this)).fail((() => {
            this.displayGroups = [];
            setTimeout(() => this.index(retryIn), Math.max(retryIn + 1000, 30000));
          }).bind(this))
        ),
      ]).then(() => this.loading = false);
    },
    loadTagVariations (tags = {}) {
      const variationIds = this.items
        .filter((item) => Object.entries(tags).every(([type, tag]) => !tag || item.tags[type] === tag))
        .map((item) => item.variation_id)
        .filter((id) => !this.variationsData[id]);

      this.loadVariationIds(variationIds);
    },
    loadVariationIds (variationIds = [], loadSingle = false, retryIn = 1000) {
      const missingVariationIds = variationIds.filter((id) => !this.variationsData[id]);
      const chunkSize = 50;

      for (let i = 0; i < missingVariationIds.length; i += chunkSize) {
        const chunk = missingVariationIds.slice(i, i + chunkSize);

        $.get({
          url: "/rest/io/variations",
          data: { variationIds: chunk },
        }).done(((response) => {
          for (const variation of JSON.parse(response).data.documents) {
            this.variationsData = {
              ...this.variationsData,
              [variation.id]: variation,
            };
          }
          const notLoadedIds = chunk.filter((id) => !this.variationsData[id]);
          for (const id of notLoadedIds) {
            this.getVariationData(id);
          }
        }).bind(this)).fail((() => {
          setTimeout(() => this.loadVariationIds(chunk, loadSingle, retryIn), Math.max(retryIn + 1000, 30000));
        }).bind(this));
      }
    },
    selectVariation (variationId) {
      this.currentItem = this.items.find((item) => item.variation_id === variationId);

      this.updateSelectedTags();

      this.loading = true;
      Promise.all([
        this.updateEnabledOptions(),
        this.getVariationData(this.currentItem.variation_id).then((data) => {
          this.currentVariationData = data;

          if (this.currentItem && this.currentVariationData) {

            const variationData = this.currentVariationData.data;
            const itemId = variationData.item.id;
            const variationId = this.currentVariationData.id;
            const name1 = variationData.texts.name1;
            const url = '/' + [variationData.texts.urlPath, itemId, variationId].filter(Boolean).join('_');

            window.history.pushState({}, name1, url);

            return this.updateItemInfo();
          } else if (this.currentItem) {
            CeresNotification.error("Es wurde keine passende Variation gefunden.");
          }
        }),
      ]).then(() => this.loading = false);
    },
    updateItemInfo () {
      return new Promise((resolve) => {
        const variationData = this.currentVariationData.data;
        const variationId = this.currentVariationData.id;
        const name1 = variationData.texts.name1;

        $('.breadcrumb-item active').text(name1);
        $('.item-description').html(variationData.texts.description);
        this.updateFreeText();

        ceresStore.dispatch(`${this.itemId}/loadVariation`, variationId).then(variation => {
          document.dispatchEvent(new CustomEvent("onVariationChanged", {
            detail: {
              attributes: variation.attributes,
              documents: variation.documents,
              itemId: this.itemId
            }
          }));

          resolve();
        });
      });
    },
    updateFreeText () {
      const itemId = this.currentVariationData ? this.currentVariationData.data.item.id : this.itemId;
      const handler = ((freeTexts) => {
        this.currentConditionDescription = freeTexts.free4;

        const [
          branch1,
          branch2,
          branch3,
          branch4,
        ] = $('.breadcrumbs .breadcrumb').text().split('\n').slice(0, -1).map(c => c.trim()).filter(Boolean);
        const name1 = this.currentVariationData ? this.currentVariationData.data.texts.name1 : "";
        const maximumOrderQuantity = this.currentVariationData ? parseInt(this.currentVariationData.data.maximumOrderQuantity || 'Infinity') : Infinity;
        const stock = parseInt(freeTexts.free18 || 'Infinity');
        const isUsImport = new RegExp("Wii U.+(US Import)", "i").test(name1)
          ? true
          : false;

        const langs = freeTexts.free2 ? freeTexts.free2.split(',') : [];
        const categoryBreadcrumbs = [branch1, branch2, branch3, branch4].filter(Boolean);

        const uskImage = (() => {
          switch (freeTexts.free8) {
            case "-1": return 'unbekannt';
            case "0": return '<img class="img-fluid" src="/images/beta/images/layout/artikel_img/USK0.png" width="20" height="20"/>';
            case "6": return '<img class="img-fluid" src="/images/beta/images/layout/artikel_img/USK6.png" width="20" height="20"/>';
            case "12": return '<img class="img-fluid" src="/images/beta/images/layout/artikel_img/USK12.png" width="20" height="20"/>';
            case "16": return '<img class="img-fluid" src="/images/beta/images/layout/artikel_img/USK16.png" width="20" height="20"/>';
            case "18": return '<img class="img-fluid" src="/images/beta/images/layout/artikel_img/USK18.png" width="20" height="20"/>';
            default: return '';
          }
        })();

        const genre = branch3 && branch2 === "Spiele" ? branch3 : '';
        const subGenre = branch4 && branch2 === "Spiele" ? branch4 : '';
        const subCategory = branch4 && branch2 !== "Spiele" ? branch4 : '';
        const categoryBreadcrumbsHtml = categoryBreadcrumbs.map(category => category ? `>&nbsp;${category}&nbsp;` : '').join('');

        const html = `
          <div class="row">
            <div class="col-12">
              <ul>
                ${freeTexts.free2 ? `
                  <li>
                    <span class="item_key">Sprache:</span>
                    <span class="item_value value_lang">
                      ${langs.map(lang => `<img class="img-fluid kk-border" src="/images/beta/images/layout/artikel_img/${lang.trim()}.png" width="24" height="18"/>`).join('')}
                    </span>
                  </li>` : ''}
                ${freeTexts.free6 ? `<li><span class="item_key">Untertitel:</span><span class="item_value">${freeTexts.free6}</span></li>` : ''}
                ${freeTexts.free7 ? `<li><span class="item_key">Verpackung:</span><span class="item_value">${freeTexts.free7}</span></li>` : ''}
                ${freeTexts.free5 ? `<li><span class="item_key">Version:</span><span class="item_value">${freeTexts.free5}</span></li>` : ''}
                ${genre ? `<li><span class="item_key">Genre:</span><span class="item_value">${genre}</span></li>` : ''}
                ${categoryBreadcrumbsHtml ? `<li style="flex: 0 0 100%"><span class="productinfo-category">Kategorie:</span><div style="display:content">${categoryBreadcrumbsHtml}</div></li>` : ''}
                ${subGenre ? `<li><span class="item_key">Sub-Genre:</span><span class="item_value">${subGenre}</span></li>` : ''}
                ${subCategory ? `<li><span class="item_key">Sub-Kategorie:</span><span class="item_value">${subCategory}</span></li>` : ''}
                <li><span class="item_key">Artikelnummer:</span><span class="item_value">${freeTexts.id}</span></li>
                ${freeTexts.free8 ? `<li><span class="item_key">USK Freigabe:</span><span class="item_value">${uskImage}</span></li>` : ''}
              </ul>
            </div>
          </div>
          ${isUsImport ? `
            <div class="mt-3">
              <strong id="us-import" class="k0k0rot">Hinweis:</strong><br />
              <p id="notification">US Import-Spiele für die Wii U sind nicht kompatibel mit deutschen Konsolen!</p>
            </div>` : ''}
          ${freeTexts.free8 === "18" ? `
            <div class="mt-3">
              <strong class="k0k0rot">Achtung!</strong><br />
              <p>Keine Jugendfreigabe gemäß § 14 JuSchG! Eine Lieferung an Minderjährige ist nicht möglich. Klicken Sie bitte <a href="/versand" target="_blank" style="text-decoration:underline;" title="Weitere Informationen zum USK 18 Versand">hier</a> für weitere Informationen.</p>
            </div>` : ''}
        `;

        $('.singleitem-information-container').html(html);

        if (stock == 1) {
          $('.item-warning')
            .removeClass('text-danger text-success text-primary text-secondary')
            .addClass('text-warning')
            .html(`<b>Nur noch ${freeTexts.free18} Artikel verfügbar!</b>`)
        } else if (stock > 1 && stock < 4 && maximumOrderQuantity > 3) {
          $('.item-warning')
            .removeClass('text-danger text-success text-primary text-secondary')
            .addClass('text-warning')
            .html(`<b>Nur noch ${freeTexts.free18} Artikel verfügbar!</b>`)
        } else {
          $('.item-warning').html('');
        }
      }).bind(this);

      if (itemId && this.freeTextData[itemId]) {
        handler(this.freeTextData[itemId]);
      } else if (itemId) {
        $.get(`/rest/kk/item/${itemId}/free_texts`).done(((response) => {
          const data = JSON.parse(response).data;
          this.freeTextData = {
            ...this.freeTextData,
            [itemId]: data,
          };
          if (data) handler(data);
        }).bind(this));
      }
    },
    updateSelectedTags () {
      this.currentTags = { ...this.currentItem.tags };

      this.displayGroups = this.displayGroups.map((group) => {
        const selectedTag = this.currentTags[group.type];
        group.options.forEach((option) => {
          option.selected = option.label === selectedTag;
        });

        return group;
      });
    },
    updateEnabledOptions () {
      return new Promise((resolve) => {
        this.displayGroups = this.displayGroups.map((group) => {
          const { ignoreTags } = group;
          const ignoreTagsSet = new Set(ignoreTags);
          const { [group.type]: currentTag, ...otherSelectedTags } = this.currentTags;
          const matchingItems = this.items.filter((item) =>
            Object.entries(otherSelectedTags).every(
              ([typeStr, tag]) => {
                const type = parseInt(typeStr);
                const otherGroup = this.displayGroups.find((group) => group.type === type);
                return (!tag || item.tags[type] === tag) || !otherGroup || ignoreTagsSet.has(type);
              }
            )
          );
          const alwaysShowTags = new Set(group.alwaysShowTags || []);
          const keepOutOfStockTags = new Set(group.keepOutOfStockTags || []);
          const keepAlternativeOnlyTags = new Set(group.keepAlternativeOnlyTags || []);
          const matchingHaveStockTags = new Set(matchingItems.filter((item) => item.has_stock).map((item) => item.tags[group.type]));
          const anyHaveStockTags = new Set(this.items.filter((item) => item.has_stock).map((item) => item.tags[group.type]));
          const enabledTags = new Set(this.items.map((item) => item.tags[group.type]));

          let filledTags = new Set();
          group.enabledOptions = group.options
            .filter(
              (option) => {
                if (option.selected || matchingHaveStockTags.has(option.label)) return true;
                if (
                  alwaysShowTags.has(option.label) ||
                  (
                    (!group.hideOutOfStockTags || keepOutOfStockTags.has(option.label)) &&
                    (!group.hideDisabledTags || enabledTags.has(option.label)) &&
                    (!group.hideAllOutOfStockTags || anyHaveStockTags.has(option.label))
                  )
                ) {
                  filledTags.add(option.label);
                  return true;
                }

                return false;
              }
            )
            .map((option) => this.getOption(option, group))
            .filter((option) => {
              if (option.selected || option.variationId) return true;
              if (!group.hideAlternativeOnlyTags || keepAlternativeOnlyTags.has(option.label)) {
                filledTags.add(option.label);
                return true;
              }
            });

          if (group.fillMissingTagsCount && group.enabledOptions.length > group.fillMissingTagsCount) {
            for (const tag of [...filledTags].reverse()) {
              const index = group.enabledOptions.findIndex((option) => option.label === tag);
              group.enabledOptions.splice(index, 1);

              if (group.enabledOptions.length <= group.fillMissingTagsCount) break;
            }
          }

          group.hide = group.enabledOptions.length < (group.hideWhenLessThan || 0)
            || group.enabledOptions.length > (group.hideWhenMoreThan || Infinity);

          if (group.thumbnailOnly) {
            // Load all variations that need a thumbnail
            const variationIds = new Set(group.enabledOptions.map(option => option.variationId).filter(Boolean));
            this.loadVariationIds([...variationIds], true);
          }

          if (group.sortByHasStock) {
            group.enabledOptions.sort((a, b) =>
              (a.stats.alternativeHasStock ? 0 : 1) - (b.stats.alternativeHasStock ? 0 : 1) ||
              (a.variationId ? 0 : 1) - (b.variationId ? 0 : 1) ||
              (a.stats.hasStock ? 0 : 1) - (b.stats.hasStock ? 0 : 1)
            );
          }
          if (group.sortSelectedFirst) {
            group.enabledOptions.sort((a, b) =>
              (a.selected ? 0 : 1) - (b.selected ? 0 : 1)
            );
          }

          return group;
        });

        resolve();
      });
    },
    getVariationData (variationId) {
      return new Promise(((resolve) => {
        const cachedData = this.variationsData[variationId];

        if (cachedData) {
          resolve(cachedData);
          return;
        }

        // If Articles has "hide in item list" flag it cannot be loaded with multiple ids
        $.get({
          url: `/rest/io/variations/${variationId}`,
        }).done(((response) => {
          const [variation] = JSON.parse(response).data.documents;
          if (variation) {
            this.variationsData = {
              ...this.variationsData,
              [variationId]: variation,
            };
            resolve(variation);
          }
        }).bind(this)).fail(() => {
          resolve(undefined)
        });
      }).bind(this));
    },
    getOption (option, group) {
      if (this.currentTags[group.type] === option.label) {
        const alternativeItem = option.alternativeVariationId
          ? this.items.find((item) => item.variation_id === option.alternativeVariationId)
          : undefined;
        return {
          ...option,
          variationId: this.currentItem.variation_id,
          stats: this.getStats(this.currentItem, alternativeItem, group),
        };
      }

      const tags = { ...this.currentTags, [group.type]: option.label };

      const item = this.getItemForTags(tags, group.type);
      const alternativeItem = item ? null : this.getItemForTags(tags, group.type, true);

      return {
        ...option,
        variationId: item ? item.variation_id : undefined,
        alternativeVariationId: alternativeItem ? alternativeItem.variation_id : undefined,
        disabled: !item,
        stats: this.getStats(item, alternativeItem, group),
      };
    },
    getStats (item, alternativeItem, group) {
      const hasStock = item && item.has_stock;
      const alternativeHasStock = alternativeItem && alternativeItem.has_stock;
      const outOfStockFormatted = item
        ? hasStock
          ? ""
          : this.soldOutText
        : this.missingItemText;
      const alternativeFormatted = alternativeItem
        ? alternativeHasStock
          ? this.alternativeAvailableText
          : this.alternativeSoldOutText
        : "";
      const price = item ? item.price : 0;
      const priceFormatted = `${group.ignoreTags && group.ignoreTags.length > 0 ? 'ab ' : ''}${this.formatPrice(price)}`;
      const priceDifference = item && this.currentItem ? item.price - this.currentItem.price : undefined;
      const priceDifferenceFormatted = priceDifference ? this.formatPrice(priceDifference, true) : "";

      return {
        price,
        priceDifference,
        hasStock,
        alternativeHasStock,
        priceFormatted,
        priceDifferenceFormatted,
        outOfStockFormatted,
        alternativeFormatted,
      };
    },
    getItemForTags (tags, updatedGroup, force) {
      if (Object.values(tags).every(([type, tag]) => this.currentTags[type] === tag)) {
        return this.currentItem
      }

      const {
        ignoreTags,
        ignoredTagsSortOrder
      } = this.variationGroups.find((group) => group.type === updatedGroup);
      const ignoreTagsSet = new Set(ignoreTags);
      let items = [...this.items]

      if (force) {
        items = items
          .filter(item => item.tags[updatedGroup] === tags[updatedGroup])
          .sort((a, b) => {
            const matchingTagsA = this.getMatchingItemTags(a, tags, ignoreTagsSet).length;
            const matchingTagsB = this.getMatchingItemTags(b, tags, ignoreTagsSet).length;

            return ((a.has_stock ? 0 : 1) - (b.has_stock ? 0 : 1)) || (matchingTagsB - matchingTagsA);
          });

        return items[0];
      }

      const matchTags = Object.entries(tags)
        .filter(([typeStr]) => {
          const type = parseInt(typeStr);
          return type === updatedGroup || (this.displayGroupTypes.has(type) && !ignoreTagsSet.has(type));
        })

      items = items.filter((item) => matchTags.every(([type, tag]) => item.tags[type] === tag));

      if (ignoreTagsSet.size !== 0) {
        switch (ignoredTagsSortOrder) {
          case "price":
            items.sort((a, b) => a.price - b.price);
            break;
          case "salesrank":
            items.sort((a, b) => a.sales_rank - b.sales_rank);
            break;
        }
      }

      // Always keep stock items on top
      items.sort((a, b) => (a.has_stock ? 0 : 1) - (b.has_stock ? 0 : 1));

      return items[0];
    },
    getMatchingItemTags (item, tags, updatedGroup = null, ignoreTagsSet = new Set()) {
      return Object.entries(tags).filter(([typeStr, tag]) => {
        const type = parseInt(typeStr);
        return (updatedGroup === null || type !== updatedGroup) &&
          (item.tags[type] === tag || ignoreTagsSet.has(type));
      });
    },
    formatPrice (price, asDifference = false, currency = " €") {
      const numeric = parseFloat(price.toString().replace(",", ""));
      const prefix = asDifference && numeric > 0 ? "+" : "";
      const formattedPrice = !isNaN(numeric) ? numeric.toFixed(2).replace(".", ",") : '';

      return [prefix, formattedPrice, currency].join("");
    },
  },
  created () {
    this.index();

    // Listen for back/forward navigation
    window.addEventListener("popstate", () => {
      const parts = /\/-(\d+)|(\d+)_\d+\//.exec(window.location.pathname)
      const itemId = parts ? parts[1] || parts[2] : undefined;

      if (itemId) {
        const item = this.items.find((item) => item.article_id === parseInt(itemId));
        this.selectVariation(item.variation_id);
      }
    });
  },
  watch: {
    currentTags (tags, oldTags) {
      const loadVariationTags = this.displayGroups.reduce((acc, group) => {
        if (tags[group.type] !== oldTags[group.type] && group.onlyLoadMatchingVariations) {
          acc[group.type] = tags[group.type];
        }
        return acc;
      });

      if (Object.keys(loadVariationTags).length) {
        this.loadTagVariations(loadVariationTags);
      }
    }
  }
});
