Vue.component("item-variation-select-tag", {
  template: "#vue-item-variation-select-tag",
  props: {
    disabled: Boolean,
    group: Object,
    value: String,
    description: String,
    highlight: Boolean,
    collapseAfterCount: {
      type: Number,
      default: 8,
    },
    soldOutTooltip: {
      type: String,
      default: "Bald wieder da - Jetzt Kaufalarm aktivieren!",
    },
    alternativeAvailableTooltip: {
      type: String,
      default: "Nur in Kombination mit anderen Merkmalen verfügbar.",
    },
    alternativeSoldOutTooltip: {
      type: String,
      default: "Ausverkauft - Nur in Kombination mit anderen Merkmalen vorhanden.",
    },
    missingItemTooltip: {
      type: String,
      default: "Artikelvariante leider nicht vorhanden",
    },
    variationsData: Object,
  },
  data: () => ({
    collapsed: true,
  }),
  computed: {
    options () {
      if (!this.collapsed || this.group.enabledOptions.length <= this.collapseAfterCount) {
        return this.group.enabledOptions;
      } else if (this.group.enabledOptions.findIndex((option) => option.selected) < this.collapseAfterCount - 1) {
        return this.group.enabledOptions.slice(0, this.collapseAfterCount - 1);
      } else {
        return [
          ...this.group.enabledOptions.slice(0, this.collapseAfterCount - 2),
          this.group.enabledOptions.find((option) => option.selected),
        ]
      }
    },
    currentOption () {
      return this.group.enabledOptions.find((option) => option.selected)
    },
    hiddenOptionsCount () {
      return this.group.enabledOptions.length - this.options.length;
    }
  },
  methods: {
    selectVariation (variationId) {
      this.$emit("input", variationId);
    },
    toggleCollapse () {
      this.collapsed = !this.collapsed;
    },
    showStats (option, group, selected = false, separator = ', ') {
      const { displayStats, selectedDisplayStats, displayStatsMaxCount } = group;
      const { stats } = option;
      const result = [];

      for (let key of (selected ? selectedDisplayStats : displayStats)) {
        if (stats[key]) {
          result.push(stats[key]);
        }

        if (displayStatsMaxCount && result.length >= displayStatsMaxCount) {
          break;
        }
      }

      return result.join(separator);
    },
    getTitle (option) {
      const prefix = this.group.thumbnailOnly ? option.label : '';
      const suffix = option.alternativeVariationId ?
        option.stats.alternativeHasStock
          ? ` (${this.alternativeAvailableTooltip})`
          : ` (${this.alternativeSoldOutTooltip})`
        : '';

      if (option.disabled) {
        return `${prefix}: ${this.missingItemTooltip}${suffix}`;
      } else if (option.variationId && !option.stats.hasStock) {
          return `${prefix}: ${this.soldOutTooltip}${suffix}`;
      } else {
        return `${prefix ?? 'Standard'}${suffix}`;
      }
    },
    getThumbnail (option) {
      const variationId = option.variationId ?? option.alternativeVariationId;

      if (variationId && this.variationsData && this.variationsData[variationId]) {
        const image = this.variationsData[variationId].data.images.all[0];
        return image ? image.urlPreview : '';
      } else {
        return '';
      }
    },
    getColor (option) {
      if (/weiß|white/i.test(option.label)) return '#fff';
      if (/schwarz|black/i.test(option.label)) return '#000';
      if (/rot|\bred\b/i.test(option.label)) return '#f00';
      if (/grün|green|lime/i.test(option.label)) return '#090';
      if (/blau|blue/i.test(option.label)) return '#03f';
      if (/gelb|yellow|gold/i.test(option.label)) return '#fb0';
      if (/silber|grau|silver|gray/i.test(option.label)) return '#aaa';
      if (/lila|purple/i.test(option.label)) return '#85f';
      if (/orange|bronze/i.test(option.label)) return '#f60';
      if (/braun|brown/i.test(option.label)) return '#742';
      if (/rosa|pink/i.test(option.label)) return '#f6f';
      if (/türkis|cyan|teal/i.test(option.label)) return '#0aa';

      return '';
    },
    getVersionFlag (option) {
      switch (option.label) {
        case "ARA Version":
        case "ARA Import":
        case "ARAB Version":
        case "ARAB Import":
          return 'arab';
        case "ARE Version":
        case "ARE Import":
        case "UAE Version":
        case "UAE Import":
          return 'ae';
        case "AT Import":
        case "AT Version":
          return 'at';
        case "CH Versoin":
        case "CH Import":
        case "CHE Version":
        case "CHE Import":
          return 'ch';
        case "DE Version":
          return 'de';
        case "CHN Version":
        case "CHN Import":
        case "CN Version":
        case "CN Import":
          return 'cn';
        case "HK Import":
        case "HK Version":
        case "HKG Version":
        case "HKG Import":
          return 'hk';
        case "DK Version":
        case "DK Import":
        case "DNK Version":
        case "DNK Import":
          return 'dk';
        case "CA Version":
        case "CA Import":
        case "CAN Version":
        case "CAN Import":
          return 'ca';
        case "UK Import":
        case "UK Version":
        case "GB Version":
        case "GB Import":
          return 'gb';
        case "US Import":
        case "US Version":
          return 'us';
        case "BE Version":
        case "BE Import":
        case "BEL Version":
        case "BEL Import":
          return 'be';
        case "BNL Import":
        case "BNL Version":
          return 'bnl';
        case "CZ Version":
        case "CZ Import":
        case "CZE Version":
        case "CZE Import":
          return 'cz';
        case "GRC Version":
        case "GRC Import":
          return 'gr';
        case "HUN Version":
        case "HUN Import":
          return 'hu';
        case "SK Version":
        case "SK Import":
        case "SVK Version":
        case "SVK Import":
          return 'sk';
        case "EU Version":
          return 'eu';
        case "FIN Version":
        case "FIN Import":
          return 'fi';
        case "FR Version":
        case "FR Import":
        case "FRA Version":
        case "FRA Import":
          return 'fr';
        case "IT Version":
        case "IT Import":
        case "ITA Version":
        case "ITA Import":
          return 'it';
        case "JAP Import":
        case "JAP Version":
          return 'jp';
        case "NL Version":
        case "NL Import":
        case "NLD Import":
        case "NLD Version":
        case "HOL Version":
        case "HOL Import":
          return 'nl';
        case "NOR Version":
        case "NOR Import":
          return 'no';
        case "POL Version":
        case "POL Import":
          return 'pl';
        case "BRA Version":
        case "BRA Import":
          return 'br';
        case "PORT Version":
        case "PORT Import":
        case "PRT Version":
        case "PRT Import":
          return 'pt';
        case "RU Version":
        case "RU Import":
        case "RUS Import":
        case "RUS Version":
          return 'ru';
        case "ESP Version":
        case "ESP Import":
          return 'se';
        case "SWE Version":
        case "SWE Import":
          return 'se';
        case "AU Version":
        case "AU Import":
        case "AUS Import":
        case "AUS Version":
          return 'au';
        case "KOR Version":
        case "KOR Import":
          return 'kr';
        case "SAU Version":
        case "SAU Import":
          return 'sa';
        case "SGP Version":
        case "SGP Import":
          return 'sg';
        case "TR Version":
        case "TR Import":
        case "TUR Version":
        case "TUR Import":
          return 'tr';
        case 'PAL':
        case 'PAL-A':
        case 'PAL-B':
        case 'PAL-ASIA':
        case 'PAL-I':
          return 'pal';
        case 'NTSC':
        case 'NTSC-J':
          return 'ntsc';
        default:
          return "";
      }
    },
  },
});
