Vue.component("single-add-to-basket-extended", {
  template: "#vue-single-add-to-basket-extended",
  props: {
    buttonSize: String,
    paddingClasses: String,
    paddingStyles: String,
  },
  data: () => ({
    intersectionObserver: null,
    isAddToBasketButtonVisible: false,
    scrollListener: null,
  }),
  inject: {
    itemId: {
      default: null
    }
  },
  computed: {
    currentVariation () {
      return this.$store.getters[`${this.itemId}/currentItemVariation`]
    },
    thumbnail () {
      return this.$options.filters.itemImages(
        this.currentVariation.images,
        "urlPreview"
      )[0];
    },
  },
  created () {
    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        this.isAddToBasketButtonVisible = entry.isIntersecting;
      });
    }, {
      threshold: 0.5
    });
  },
  mounted () {
    const scrollListener = (() => {
      if (this.$refs.addToBasketButton) {
        this.intersectionObserver.observe(this.$refs.addToBasketButton.$el);
        window.removeEventListener("scroll", scrollListener);
        this.scrollListener = null;
      }
    }).bind(this);
    window.addEventListener("scroll", scrollListener);

    setTimeout(100, (() => {
      // Force update when already in viewport
      if (this.scrollListener) {
        this.scrollListener();
      }
    }).bind(this));

  },
  unmounted () {
    if (this.$refs.addToBasketButton) {
      this.intersectionObserver.unobserve(this.$refs.addToBasketButton.$el);
    }
  },
});