Vue.component("newsletter-form-fallback", {
  template: "#vue-newsletter-form-fallback",
  props: {
    listId: String,
    source: {
      type: String,
      default: "DE-Embed5%-Startseite (Fallback)",
    },
    companyId: {
      type: String,
      default: "Yxcfgn",
    }
  },
  data () {
    return {
      email: "",
      firstName: "",
    };
  },
  created () {
    window._klOnsite = window._klOnsite || [];
  },
  methods: {
    onSubmit (event) {
      $.ajax({
        url: `https://a.klaviyo.com/client/subscriptions/?company_id=${this.companyId}&onsite=true`,
        type: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'Accept': 'application/vnd.api+json',
          'revision': '2025-01-15',
        },
        data: JSON.stringify({
          data: {
            type: "subscription",
            attributes: {
              profile: {
                data: {
                  type: "profile",
                  attributes: {
                    properties: {
                      first_name: this.firstName,
                      email: this.email,
                    },
                  },
                }
              },
              custom_source: this.source,
            },
            relationships: {
              list: {
                data: {
                  id: this.listId,
                  type: "list",
                },
              },
            },
          },
        }),
      }).done(() => {
        console.log("Successfully subscribed to the newsletter");
      }).fail(() => {
        console.error("Failed to subscribe to the newsletter");
      });
    }
  },
});
